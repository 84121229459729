<template>
  <div class="info-icon">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 95 104.13"
      >
        <g>
          <circle
            class="cls-2"
            style="stroke-width: 0px; fill: #fff"
            cx="47.5"
            cy="47.5"
            r="43.5"
          />
          <path
            fill="currentcolor"
            class="cls-1"
            d="m47.5,8c21.78,0,39.5,17.72,39.5,39.5s-17.72,39.5-39.5,39.5S8,69.28,8,47.5,25.72,8,47.5,8m0-8C21.27,0,0,21.27,0,47.5s21.27,47.5,47.5,47.5,47.5-21.27,47.5-47.5S73.73,0,47.5,0h0Z"
          />
        </g>
        <path
          fill="currentcolor"
          style="stroke-width: 0px"
          class="cls-1"
          d="m53.83,19.49v9.44h-11.04v-9.44h11.04Zm0,14.72v43.04h-11.04v-43.04h11.04Z"
        />
      </svg>
  </div>
</template>
