import { h } from "vue";

export const devicesSatelliteDishSvg = h(
  "svg",
  { viewBox: "0 0 512 512" },
  h("path", {
    fill: "currentColor",
    d: "M192 24c0 13.3 10.7 24 24 24c137 0 248 111 248 248c0 13.3 10.7 24 24 24s24-10.7 24-24C512 132.5 379.5 0 216 0c-13.3 0-24 10.7-24 24zm24 80c-13.3 0-24 10.7-24 24s10.7 24 24 24c79.5 0 144 64.5 144 144c0 13.3 10.7 24 24 24s24-10.7 24-24c0-106-86-192-192-192zm-7 233l40-40c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-40 40L71 199c-13.5-13.5-35.9-12.3-45.1 4.4C9.4 233.2 0 267.5 0 304C0 418.9 93.1 512 208 512c36.5 0 70.8-9.4 100.6-25.9c16.7-9.2 17.8-31.6 4.4-45.1L209 337zM48 304c0-18.1 3-35.5 8.5-51.6L259.6 455.5C243.5 461 226.1 464 208 464c-88.4 0-160-71.6-160-160z",
  })
);

export const packagesBoxSvg = h(
  "svg",
  { viewBox: "0 0 512 512" },
  h("path", {
    fill: "currentColor",
    d: "M59.6 160L95.2 80H200v80H59.6zM248 160V80H352.8l35.6 80H248zm152 48V432H48V208H400zM384 32H64L0 176V432v48H48 400h48V432 176L384 32z",
  })
);

export const booksSvg = h(
  "svg",
  { viewBox: "0 0 512 512" },
  h("path", {
    fill: "currentColor",
    d: "M48 416v48h64V416H48zm88 89.6c-7.1 4.1-15.3 6.4-24 6.4H48c-26.5 0-48-21.5-48-48V416 392 368 144 120 96 48C0 21.5 21.5 0 48 0h64c8.7 0 16.9 2.3 24 6.4C143.1 2.3 151.3 0 160 0h64c20.6 0 38.1 12.9 45 31.1c5.6-6.1 12.9-10.7 21.4-13L349.9 1.6c24.7-6.8 50.1 8.3 56.7 33.8l18 69.2 6 23.2 61.8 238.3 6 23.2 11.9 46c6.6 25.5-8 51.7-32.7 58.5l-59.6 16.5c-24.7 6.8-50.1-8.3-56.7-33.8l-18-69.2-6-23.2L275.6 145.9 272 132.2V144 368v24 24 48c0 26.5-21.5 48-48 48H160c-8.7 0-16.9-2.3-24-6.4zM160 464h64V416H160v48zM112 48H48V96h64V48zm0 96H48V368h64V144zm48-48h64V48H160V96zm64 272V144H160V368h64zm216.1-12.3l-55.8-215-56.5 15.6 55.8 215 56.5-15.6zm-44.4 62.1l11.9 45.7L464 447.9c0-.1 0-.2 0-.3l0-.1-11.7-45.2-56.5 15.6zm-79.9-308l56.5-15.6L360.4 48.5 304 64.1c0 .1 0 .2 0 .4l11.7 45.2z",
  })
);

export const orgsSvg = h(
  "svg",
  { viewBox: "0 0 640 512" },
  h("path", {
    fill: "currentColor",
    d: "M147.2 160a64 64 0 1 0 0-128 64 64 0 1 0 0 128zM96 192c-35.3 0-64 28.7-64 64c0 17.7 14.3 32 32 32h98.7c18.2-31.4 49.3-54.4 86-61.6C238.1 206 216.7 192 192 192H96zm480 96c17.7 0 32-14.3 32-32c0-35.3-28.7-64-64-64H448c-24.5 0-45.7 13.7-56.5 33.9c38 6.6 70.3 29.9 89 62.1H576zm-134.8 0c-14.6-16.3-34.6-27.5-57.1-30.9c-.6-.1-1.3-.2-1.9-.3c-4.1-.5-8.3-.8-12.5-.8h-48-48c-4.2 0-8.4 .3-12.5 .8c-1.7 .2-3.4 .5-5.1 .8c-21.3 3.9-40.1 14.9-53.9 30.4c-15.2 17-24.4 39.4-24.4 64c0 17.7 14.3 32 32 32h224c17.7 0 32-14.3 32-32c0-24.6-9.2-47-24.4-64zm58-128a64 64 0 1 0 0-128 64 64 0 1 0 0 128zM321.6 112a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm-48 96l0 0c13.4 10 30 16 48 16s34.6-6 48-16l0 0c19.4-14.6 32-37.8 32-64c0-44.2-35.8-80-80-80s-80 35.8-80 80c0 26.2 12.6 49.4 32 64zM414.9 336H228.3c6.6-18.6 24.4-32 45.3-32h96c20.9 0 38.7 13.4 45.3 32zM0 456c0 13.3 10.7 24 24 24H616c13.3 0 24-10.7 24-24s-10.7-24-24-24H24c-13.3 0-24 10.7-24 24z",
  })
);

export const integrationsSvg = h(
  "svg",
  { viewBox: "0 0 640 512" },
  h("path", {
    fill: "currentColor",
    d: "M272.2 64.6l-51.1 51.1c-15.3 4.2-29.5 11.9-41.5 22.5L153 161.9C142.8 171 129.5 176 115.8 176H96V304c20.4 .6 39.8 8.9 54.3 23.4l35.6 35.6 7 7 0 0L219.9 397c6.2 6.2 16.4 6.2 22.6 0c1.7-1.7 3-3.7 3.7-5.8c2.8-7.7 9.3-13.5 17.3-15.3s16.4 .6 22.2 6.5L296.5 393c11.6 11.6 30.4 11.6 41.9 0c5.4-5.4 8.3-12.3 8.6-19.4c.4-8.8 5.6-16.6 13.6-20.4s17.3-3 24.4 2.1c9.4 6.7 22.5 5.8 30.9-2.6c9.4-9.4 9.4-24.6 0-33.9L340.1 243l-35.8 33c-27.3 25.2-69.2 25.6-97 .9c-31.7-28.2-32.4-77.4-1.6-106.5l70.1-66.2C303.2 78.4 339.4 64 377.1 64c36.1 0 71 13.3 97.9 37.2L505.1 128H544h40 40c8.8 0 16 7.2 16 16V352c0 17.7-14.3 32-32 32H576c-11.8 0-22.2-6.4-27.7-16H463.4c-3.4 6.7-7.9 13.1-13.5 18.7c-17.1 17.1-40.8 23.8-63 20.1c-3.6 7.3-8.5 14.1-14.6 20.2c-27.3 27.3-70 30-100.4 8.1c-25.1 20.8-62.5 19.5-86-4.1L159 404l-7-7-35.6-35.6c-5.5-5.5-12.7-8.7-20.4-9.3C96 369.7 81.6 384 64 384H32c-17.7 0-32-14.3-32-32V144c0-8.8 7.2-16 16-16H56 96h19.8c2 0 3.9-.7 5.3-2l26.5-23.6C175.5 77.7 211.4 64 248.7 64H259c4.4 0 8.9 .2 13.2 .6zM544 320V176H496c-5.9 0-11.6-2.2-15.9-6.1l-36.9-32.8c-18.2-16.2-41.7-25.1-66.1-25.1c-25.4 0-49.8 9.7-68.3 27.1l-70.1 66.2c-10.3 9.8-10.1 26.3 .5 35.7c9.3 8.3 23.4 8.1 32.5-.3l71.9-66.4c9.7-9 24.9-8.4 33.9 1.4s8.4 24.9-1.4 33.9l-.8 .8 74.4 74.4c10 10 16.5 22.3 19.4 35.1H544zM64 336a16 16 0 1 0 -32 0 16 16 0 1 0 32 0zm528 16a16 16 0 1 0 0-32 16 16 0 1 0 0 32z",
  })
);

export const webhookSvg = h(
  "svg",
  { viewBox: "0 0 576 512" },
  h("path", {
    fill: "currentColor",
    d: "M306 50c-43.1-9.9-86 16.9-95.9 60c-7.9 34.1 7.4 68.2 35.5 85.9c5.4 3.4 9.2 8.8 10.7 15s.3 12.7-3 18.1L170.4 361.5c3.6 6.7 5.6 14.4 5.6 22.5c0 26.5-21.5 48-48 48s-48-21.5-48-48s21.5-48 48-48c.6 0 1.1 0 1.7 0L201 221.9c-32.5-30.2-48.4-76.4-37.7-122.7C179.2 30.3 247.9-12.6 316.8 3.3c65.9 15.2 108 78.7 97.7 144.4c-2.1 13.1-14.3 22-27.4 20s-22-14.3-20-27.4C373.5 99.2 347.1 59.5 306 50zM289.7 176c-.6 0-1.1 0-1.7 0c-26.5 0-48-21.5-48-48s21.5-48 48-48s48 21.5 48 48c0 8.1-2 15.8-5.6 22.5l71.3 114.1c45.8-17.7 99.8-8.2 136.8 28.9c50 50 50 131 0 181c-43 43-109 49-158.4 18c-11.2-7-14.6-21.8-7.6-33.1s21.8-14.6 33.1-7.6c30.9 19.3 72.1 15.5 99-11.3c31.2-31.2 31.2-81.9 0-113.1c-26.8-26.8-68.1-30.6-99-11.3c-5.4 3.4-11.9 4.5-18.1 3s-11.6-5.3-15-10.7L289.7 176zM448 432c-17.8 0-33.3-9.7-41.6-24H253.8c-9.2 48.3-46 88.9-97 100.7c-68.9 15.9-137.6-27-153.5-95.9C-11 351 22.1 289.4 78.7 265.8c12.2-5.1 26.3 .7 31.4 12.9s-.7 26.3-12.9 31.4c-35.4 14.7-56 53.3-47.1 91.8c9.9 43.1 52.9 69.9 95.9 60c37-8.5 62.1-41.5 62-77.9c0-6.4 2.5-12.5 7-17s10.6-7 17-7H406.4c8.3-14.3 23.8-24 41.6-24c26.5 0 48 21.5 48 48s-21.5 48-48 48z",
  })
);

export const eventHubSvg = h(
  "svg",
  { viewBox: "0 0 18 18" },
  [
    h("path", {
      fill: "#FFFFFF",
      d: "M10.83 8.42a.26.26 0 01-.24.27H8.5a.26.26 0 01-.27-.24V6.89a.26.26 0 01.24-.27h2.09a.26.26 0 01.27.24v1.56zM14.54 10a.26.26 0 01-.24.27h-2.09a.26.26 0 01-.27-.24V8.48a.26.26 0 01.24-.27h2.09a.26.26 0 01.27.24V10zM10.83 11.6a.26.26 0 01-.24.27H8.5a.26.26 0 01-.27-.24v-1.56a.26.26 0 01.24-.27h2.09a.26.26 0 01.27.24v1.56z"
    }),
    h("path", {
      fill: "#FFFFFF",
      d: "M7.12 6.84a.25.25 0 01-.23.26H4.74a.26.26 0 01-.27-.23V5.25A.26.26 0 014.71 5H6.8c.22 0 .32.11.32.27z"
    }),
    h("path", {
      fill: "#FFFFFF",
      d: "M7.12 10a.25.25 0 01-.23.27H4.74a.26.26 0 01-.27-.27V8.42a.26.26 0 01.24-.26H6.8c.22 0 .32.11.32.26zM7.12 13.19a.25.25 0 01-.23.27H4.74a.26.26 0 01-.27-.24V11.6a.25.25 0 01.24-.26H6.8c.22 0 .32.1.32.26z"
    }),
    h("path", {
      fill: "#FFFFFF",
      d: "M1.07 1.51h1.29v3.6a.29.29 0 01-.29.29H.79a.29.29 0 01-.29-.29v-3a.57.57 0 01.57-.6z"
    }),
    h("path", {
      fill: "#FFFFFF",
      d: "M1.07 1.51h1.29v3.6a.29.29 0 01-.29.29H.79a.29.29 0 01-.29-.29v-3a.57.57 0 01.57-.6z"
    }),
    h("path", {
      fill: "#FFFFFF",
      d: "M15.64 1.51h1.29a.57.57 0 01.57.57v3a.29.29 0 01-.29.29h-1.29a.29.29 0 01-.29-.29V1.51h.01z"
    }),
    h("path", {
      fill: "#FFFFFF",
      d: "M15.64 1.51h1.29a.57.57 0 01.57.57v3a.29.29 0 01-.29.29h-1.29a.29.29 0 01-.29-.29V1.51h.01z"
    }),
    h("path", {
      fill: "#FFFFFF",
      d: "M17.5 2.08v1.25H.5V2.08a.57.57 0 01.57-.57h15.87a.57.57 0 01.56.57z"
    }),
    h("path", {
      fill: "#FFFFFF",
      d: "M.79 12.76h1.29a.29.29 0 01.29.29v3.6h-1.3a.57.57 0 01-.57-.57V13a.29.29 0 01.29-.24z"
    }),
    h("path", {
      fill: "#FFFFFF",
      d: "M.79 12.76h1.29a.29.29 0 01.29.29v3.6h-1.3a.57.57 0 01-.57-.57V13a.29.29 0 01.29-.24z"
    }),
    h("path", {
      fill: "#FFFFFF",
      d: "M15.92 12.76h1.29a.29.29 0 01.29.29v3a.57.57 0 01-.57.57h-1.29V13a.29.29 0 01.28-.24z"
    }),
    h("path", {
      fill: "#FFFFFF",
      d: "M15.92 12.76h1.29a.29.29 0 01.29.29v3a.57.57 0 01-.57.57h-1.29V13a.29.29 0 01.28-.24z"
    }),
    h("path", {
      fill: "#FFFFFF",
      d: "M.5 16.08v-1.25h17v1.25a.57.57 0 01-.57.57H1.07a.57.57 0 01-.57-.57z"
    })
  ]
);

export const datacakeSvg = h(
  "svg",
  {
    version: "1.0",
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 512.000000 512.000000",
    preserveAspectRatio: "xMidYMid meet"
  },
  [
    h("g", 
      {
        transform: "translate(0.000000,512.000000) scale(0.100000,-0.100000)",
        fill: "currentColor",
        stroke: "none"
      },
      [
        h("path", {
          d: `M209.3,5053.6c-5.3-12.5-7.1-898.8-5.3-1966.6l5.3-1945.3l1501.3,1482.5c1017.5,1003.8,1515.6,1482.5,1544,1486.1
          c65.8,8.9,288.2-64.1,382.4-124.6c97.8-62.3,195.7-167.3,188.6-201.1c-5.3-23.1-215.2-243.8-1890.9-1984.4
          C659.4,474.2,422.8,226.8,335.6,132.5L252,41.8l1339.5-7.1c1412.4-5.3,1519.1-1.8,1750.4,74.8c423.4,138.8,821.8,412.9,1147.3,792
          c288.2,336.4,453.6,662.1,551.4,1096.3c37.4,162,42.7,231.4,42.7,541.1c0,380.9-23.1,533.9-128.1,872.1
          c-55.1,178-225.9,539.3-320.2,678.1c-197.5,290.1-702.6,655-1104.7,797.3c-163.7,58.7-462.5,126.4-670.6,154.8
          c-103.2,12.5-599.5,23.1-1399.9,28.5C437,5076.7,216.4,5073.2,209.3,5053.6z M2361.7,4224.2c-39.1-39.2-330.9-313.2-649.3-610.5
          s-624.4-583.8-679.5-637.2l-103.2-96.1v704.8v706.6h750.7h752.4L2361.7,4224.2z M4357.5,2716.7c32-710.1-229.5-1270.8-784.5-1685.4
          c-154.8-115.7-341.5-211.8-523-267c-105-33.8-176.1-39.2-594.1-44.5c-380.7-7.1-476.7-3.6-476.7,14.2
          c0,12.5,309.5,336.4,690.2,717.2c378.9,382.7,900.1,911.2,1159.8,1172.9l471.4,477l23.1-76.5
          C4336.2,2981.9,4352.2,2844.9,4357.5,2716.7z`
        })
      ]
    )
  ]
);

export const eventGridSvg = h(
  "svg",
  {
    version: "1.0",
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0, 0, 400,400",
    preserveAspectRatio: "xMidYMid meet",
  },
  [
    h(
      "g",
      {
        fill: "currentColor",
      },
      [
        h("path", {
          d: `M1.481 1.473 C -1.031 3.985,-0.602 93.992,1.934 96.527 C 4.782 99.375,38.295 99.375,41.143 96.527 C 42.778 94.892,43.077 90.611,43.077 68.835 L 43.077 43.077 200.000 43.077 L 356.923 43.077 356.923 69.292 C 356.923 101.177,354.970 98.462,377.902 98.462 C 402.546 98.462,400.101 104.202,399.707 47.271 L 399.385 0.615 201.173 0.304 C 48.026 0.063,2.625 0.329,1.481 1.473 M260.257 76.160 C 257.521 77.402,253.818 80.159,252.029 82.286 L 248.774 86.154 210.229 86.154 L 171.683 86.154 110.154 147.692 L 48.625 209.231 39.697 209.231 L 30.769 209.231 30.769 221.538 L 30.769 233.846 61.240 233.846 L 91.711 233.846 122.462 264.615 L 153.212 295.385 212.990 295.385 L 272.768 295.385 275.490 299.067 C 288.901 317.205,318.769 306.603,318.769 283.704 C 318.769 261.382,290.526 250.403,276.373 267.224 L 273.390 270.769 216.987 270.769 L 160.585 270.769 142.154 252.308 L 123.723 233.846 171.169 233.846 L 218.614 233.846 221.336 237.528 C 234.751 255.673,264.615 245.064,264.615 222.154 C 264.615 199.680,235.442 188.758,221.785 206.120 L 219.338 209.231 186.915 209.231 L 154.492 209.231 172.923 190.769 L 191.354 172.308 247.756 172.308 L 304.159 172.308 307.413 176.175 C 321.873 193.360,350.715 182.586,350.715 160.000 C 350.715 137.414,321.873 126.640,307.413 143.825 L 304.159 147.692 244.070 147.692 L 183.982 147.692 153.231 178.462 L 122.480 209.231 101.553 209.231 L 80.627 209.231 129.846 160.000 L 179.065 110.769 213.920 110.769 L 248.774 110.769 252.029 114.637 C 263.284 128.013,285.688 124.893,293.058 108.923 C 302.680 88.076,281.218 66.638,260.257 76.160 M2.154 302.868 C -0.747 304.557,-1.211 396.232,1.669 398.622 C 2.894 399.639,56.026 399.925,201.361 399.696 L 399.385 399.385 399.707 352.729 C 400.101 295.798,402.546 301.538,377.902 301.538 C 354.970 301.538,356.923 298.823,356.923 330.708 L 356.923 356.923 200.000 356.923 L 43.077 356.923 43.077 331.165 C 43.077 309.389,42.778 305.108,41.143 303.473 C 38.861 301.191,5.911 300.680,2.154 302.868`,
        }),
      ]
    ),
  ]
);