<template>
  <div class="top-bar" v-if="store.state?.selectedOrg?.id">
    <h1 class="mb-4 wl-breadcrumb" v-if="!crumbs.length">
      <a
        @click="changeOrgModalRef.open()"
        class="wl-link-button font-normal"
        v-if="canChangeOrg"
        ><div>{{ store.state?.selectedOrg?.name }}</div></a
      >
      <div v-else="!canChangeOrg">{{ store.state?.selectedOrg?.name }}</div>
      &nbsp;/&nbsp;<span class="font-normal"
        ><div>{{ props.title }}</div></span
      >
      <span v-if="props.subtitle">&nbsp;/&nbsp;</span
      ><span v-if="props.subtitle" class="font-normal"
        ><div>{{ props.subtitle }}</div></span
      >
      <a v-show="infoLink" :href="infoLink" target="_blank"><InfoIcon></InfoIcon></a>
    </h1>

    <h1 class="mb-4 wl-breadcrumb" v-else>
      <div
        class="two-col-controls gap-2 mr-2"
        v-for="(crumb, index) in (crumbs as Array<any>)"
      >
        <router-link
          v-if="crumb.link"
          :to="crumb.link"
          class="wl-link-button font-normal"
        >
          <div>{{ crumb.name }}</div>
        </router-link>
        <a
          v-else-if="crumb.canChangeOrg"
          @click="changeOrgModalRef.open()"
          class="wl-link-button font-normal"
        >
          <div>{{ crumb.name }}</div>
        </a>
        <span v-else class="font-normal">
          <div>{{ crumb.name }}</div>
        </span>
        <div v-if="index < crumbs.length - 1">/</div>
      </div>
      <a v-show="infoLink" href="infoLink" target="_blank"><InfoIcon></InfoIcon></a>
    </h1>
  </div>
  <WLChangeOrgModal ref="changeOrgModalRef" />
</template>

<script lang="ts">
import { useStore } from "vuex";
import WLChangeOrgModal from "../components/modals/WLChangeOrgModal.vue";
import { ref } from "vue";

export default {
  props: {
    crumbs: { default: () => [], type: Array, required: false },
    title: { default: () => "" },
    subtitle: { default: () => "" },
    infoLink: { default: () => "" },
    canChangeOrg: {
      default: () => true,
      type: Boolean,
      required: false,
    },
  },
  components: { WLChangeOrgModal },
  setup(props) {
    const store = useStore();

    const changeOrgModalRef = ref<typeof WLChangeOrgModal>(WLChangeOrgModal);

    return { changeOrgModalRef, store, props };
  },
};
</script>
