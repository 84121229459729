<template>
  <div v-show="!profileIsHidden" class="wl-profile-widget">
    <div
      class="signed-in"
      v-if="store.state?.isAuthenticated && store.state.user?.email"
    >
      <div v-show="isOpen" @click="closeMenu" class="click-screen"></div>
      <div class="name" @click="openMenu">
        {{ store.state.user.fullName }}
      </div>
      <div class="pic" @click="openMenu">
        <div class="elipse"></div>
        <vue-gravatar :email="store.state.user.email" :size="50" />
      </div>
      <div class="menu" @click="openMenu">
        <img
          src="/assets/svgs/icons/caret-down.svg"
          style="width: 16px; height: 24px"
        />
      </div>
      <div v-show="isOpen" class="dropdown wl-shadow">
        <div><a @click="navigateTo('/profile')"><font-awesome-icon :icon="['fa', 'user']" /> Your Profile</a></div>
        <div><a @click="navigateTo('/orgs')"><font-awesome-icon :icon="['fa', 'building-user']" />  Your Organizations</a></div>
        <div><a @click="navigateTo('/apikeys')"><font-awesome-icon :icon="['fa', 'key']" />  Your API Keys</a></div>
        <hr />
        <div>
          <a
            href="http://developer.wildernesslabs.co/Meadow/Meadow.Cloud/" target="_blank"
            ><font-awesome-icon :icon="['fa', 'book']" /> Documentation</a
          >
        </div>
        <div>
          <a href="mailto:support@wildernesslabs.co"><font-awesome-icon :icon="['fa', 'envelope']" /> Support</a>
        </div>
        <hr />
        <div @click="signOut">Sign Out</div>
      </div>
    </div>
    <div v-else-if="store.state?.isAuthenticated">
      <img src="/assets/images/loader.gif" />
    </div>
  </div>
</template>

<script lang="ts">
import { apis } from "@/api/api";
import router from "@/router";
import { computed, ref } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();

    let isOpen = ref(false);

    const openMenu = () => {
      isOpen.value = true;
    };

    const closeMenu = (event: Event) => {
      event.preventDefault();
      isOpen.value = false;
    };

    const navigateTo = (to:string) =>
    {
      router.push(to);
      isOpen.value = false;
    }

    const signOut = () => {
      window.location.href = `${apis.meadowCloud}/auth/signout?redirectUrl=${window.location.origin}`;
    };

    const profileIsHidden = computed(() => store.state.display.profileIsHidden);

    return {
      profileIsHidden,
      isOpen,
      store,
      openMenu,
      closeMenu,
      navigateTo,
      signOut,
      window,
    };
  },
};
</script>
