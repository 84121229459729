import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { BootstrapVueNext } from "bootstrap-vue-next";
import { createApp } from "vue";
import JsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import VueSidebarMenu from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";
import VueGravatar from "vue3-gravatar";
import Vue3Toasity, { toast, type ToastContainerOptions } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Vue3EasyDataTable from "wl-vue3-easy-data-table";
import "wl-vue3-easy-data-table/dist/style.css";
import App from "./App.vue";
import 'vue-json-pretty/lib/styles.css';
import WLAlertPlugin from "./plugins/WLAlertPlugin";
import WLCopyToClipboardPlugin from "./plugins/WLCopyToClipboardPlugin";
import WLHoverMenuPlugin from "./plugins/WLHoverMenuPlugin";
import InfoIcon from "./plugins/widgets/InfoIcon";
import router from "./router";
import store from "./store/store";
import "./styles/fontawesome";

createApp(App)
  .component("EasyDataTable", Vue3EasyDataTable)
  .component("font-awesome-icon", FontAwesomeIcon)
  .component("json-pretty", JsonPretty)
  .use(store)
  .use(router)
  .use(BootstrapVueNext)
  .use(VueSidebarMenu)
  .use(VueGravatar)
  .use(WLHoverMenuPlugin)
  .use(WLCopyToClipboardPlugin)
  .use(WLAlertPlugin)
  .use(InfoIcon)
  .use(Vue3Toasity, {
    autoClose: 3000,
    position: toast.POSITION.BOTTOM_RIGHT,
  } as ToastContainerOptions)
  .mount("#app");