// devices
export const docs_devices_getting_started = "Learn how to <a href='https://developer.wildernesslabs.co/Meadow/Meadow.Cloud/Device_Provisioning/'>add a device</a> to the cloud!"
export const docs_devices_send_command = "Learn more about how command and control works. <a href='https://developer.wildernesslabs.co/Meadow/Meadow.Cloud/Command_Control/'>See the docs</a>"

// packages
export const docs_packages_publish = "Learn more about <a href='https://developer.wildernesslabs.co/Meadow/Meadow.Cloud/OtA_Updates/' target='_blank'>over the air updates</a> (OtA)."

// integrations
export const docs_webhooks_overview = "Learn more about integrating webhooks with Meadow.Cloud. <a href='https://developer.wildernesslabs.co/Meadow/Meadow.Cloud/Integrations/Webhooks/' target='_blank'>See the docs</a>";
export const docs_eventhubs_overview = "Learn more about integrating Azure Event Hubs with Meadow.Cloud. <a href='https://developer.wildernesslabs.co/Meadow/Meadow.Cloud/Integrations/AzureEventHubs/' target='_blank'>See the docs</a>";
export const docs_eventgrid_overview = "Learn more about integrating Azure Event Grid with Meadow.Cloud. <a href='https://developer.wildernesslabs.co/Meadow/Meadow.Cloud/Integrations/AzureEventGrid/' target='_blank'>See the docs</a>";