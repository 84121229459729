import moment from "moment";

export function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function prettyDate(dateString: string)
{
  const date = moment(dateString, "M/D/YYYY h:mm:ss A");

  return date.format("MMMM Do, YYYY");
}

export function getCleanDateWithTime(date: string) {
  if (!date) return "";
  return (
    new Date(date).toLocaleDateString() +
    ", " +
    new Date(date).toLocaleTimeString()
  );
}

export function updateRouterFromNewOrg(org: any, router: any) {
  const parts = window.location.href.split("/");
  if (parts[3]) {
    parts[3] = org.shortName;
    router.push(`/${parts.slice(3).join("/")}`);
  }
}

type BooleanObjectMap = {
  [key: string]: boolean;
};
export const mapStringsToBooleanObject = (
  strings: string[] = []
): BooleanObjectMap => {
  const result: BooleanObjectMap = {};

  strings.forEach((s) => {
    result[s] = true;
  });

  return result;
};

export function dateFromNow(date: string) {
  var m = moment(date);

  if (!m.isValid()) return "Never";

  return m.fromNow();
}

export const bytesToMegabytes = (bytes:number):string => {
  return (bytes / (1024 * 1024)).toFixed(2);
}

export const svgLoader = (url:string, element:HTMLElement) =>
{
  fetch(url)
    .then(response => response.text())
    .then(svg => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(svg, 'image/svg+xml');
      element.appendChild(doc.documentElement);
    });
}