<template>
  <WLModal ref="modalRef" class-modifiers="biggest"  @keysAltEnter="send($event)">
    <template v-slot:content>
      <h3>Send a command</h3>
      <div class="form-grid">
        <label for="name">Command Name</label>
        <input v-model="commandNameRef" type="text" id="name" />

        <div v-show="validationRef.commandNameError"></div>
        <p v-show="validationRef.commandNameError" class="text-danger left">
          A command name is required.
        </p>

        <label for="arguments">Arguments</label>
        <vue-jsoneditor
          height="400"
          mode="text"
          ref="jsonEditorRef"
          :validator="null"
          :askToFormat="true"
          v-model:text="argumentsRef"
          :queryLanguagesIds="queryLanguagesRef"
        />

        <label for="QoS">QoS</label>
        <b-form-select
          v-model="qoSRef"
          :options="qoSOptions"
          id="QoS"
        ></b-form-select>
      </div>
    </template>
    <template v-slot:buttons>
      <button @click="send($event)" class="wl-button">Send</button>
    </template>
    <template v-slot:info>
      <p v-html="docsRef"></p>
    </template>
  </WLModal>
</template>

<script lang="ts">
import WLModal from "../WLModal.vue";
import { api, apis } from "@/api/api";
import { logException } from "@/utils/app-insights";
import { ref } from "vue";
import { useStore } from "vuex";
import { toast } from "vue3-toastify";
import VueJsoneditor from "vue3-ts-jsoneditor";
import type { QueryLanguageId } from "vue3-ts-jsoneditor";
import DOMPurify from "dompurify";
import { docs_devices_send_command } from "@/models/loc/en-us";

export default {
  components: {
    WLModal,
    VueJsoneditor,
  },
  expose: ["open", "close"],
  emits: ["success", "fail"],
  setup(_props, { emit }) {
    var ids: string[];
    var mode: string;
    const modalRef = ref<typeof WLModal>(WLModal);
    const commandNameRef = ref<HTMLInputElement>();
    const argumentsRef = ref<string>(`{
   "stringArg" : "my string",
   "intArg": 23
}`);
    const jsonEditorRef = ref<VueJsoneditor>(VueJsoneditor);
    const validationRef = ref({ commandNameError: false });
    const qoSOptions = ref([
      {
        value: 0,
        text: "0 - At least once",
      },
      {
        value: 1,
        text: "1 - At most once",
      },
      {
        value: 2,
        text: "2 - Exactly once",
      },
    ]);
    const qoSRef = ref(qoSOptions.value[0].value);
    const queryLanguagesRef = ref<QueryLanguageId[]>(["javascript"]);
    const docsRef = ref(
      DOMPurify.sanitize(docs_devices_send_command, { ADD_ATTR: ["target"] })
    );
    const store = useStore();

    const open = (_mode: string, _ids: string[]) => {
      ids = _ids;
      mode = _mode;
      modalRef.value.open();
    };

    const close = () => {
      modalRef.value.close();
    };

    const valid = () => {
      var isValid = true;
      validationRef.value.commandNameError = false;

      if (!commandNameRef.value) {
        isValid = false;
        validationRef.value.commandNameError = true;
      }

      var jsonValidations = jsonEditorRef.value.$validate();
      if (jsonValidations) {
        isValid = false;
      }

      return isValid;
    };

    const send = async (event: Event) => {
      if (!valid()) return;

      const button = event.target as HTMLAnchorElement;
      button?.classList.add("loading");
      const args = JSON.parse(argumentsRef.value as string) || {};
      var response;
      try {
        if (mode === "devices") {
          response = await api.post<any, string>(
            `${apis.meadowCloud}/devices/commands`,
            {
              deviceIds: ids,
              commandName: commandNameRef.value,
              args,
              qos: qoSRef.value,
            },
            "Failed to send command to devices."
          );
        } else {
          response = await api.post<any, string>(
            `${apis.meadowCloud}/collections/commands`,
            {
              collectionIds: ids,
              commandName: commandNameRef.value,
              args,
              qos: qoSRef.value,
            },
            "Failed to send command to collections."
          );
        }
      } catch (exception: any) {
        logException({ exception }, { userId: store.state.user.id });
      }

      button?.classList.remove("loading");
      if (typeof response === "string") {
        const error = JSON.parse(response);
        let errorMessages: string[] = [];
        for (let key of Object.keys(error.errors)) {
          errorMessages.push(error.errors[key].join(", "));
        }

        toast(errorMessages.join(", "), {
          position: "top-center",
          type: "error",
        });
        emit("fail", error);
        return;
      }

      toast("Command sent!");
      emit("success");
    };

    return {
      open,
      close,
      send,
      modalRef,
      argumentsRef,
      jsonEditorRef,
      commandNameRef,
      qoSRef,
      queryLanguagesRef,
      validationRef,
      qoSOptions,
      docsRef
    };
  },
};
</script>
