import store from "@/store/store";

export function setSelectedOrg(shortName:string) {
    let selectedOrg = store.state.selectedOrg;
    if (selectedOrg) {
      selectedOrg = store.state.orgs.find(
        (org) => org.id === store.state.selectedOrg.id
      );
    }
    if (!selectedOrg && shortName) {
      selectedOrg = store.state.orgs.find(
        (org) => org.shortName === shortName
      );
    }
    if (!selectedOrg) {
      selectedOrg = store.state.orgs.find((org) => org.isDefault);
    }
    if (!selectedOrg) {
      selectedOrg = store.state.orgs[0];
    }
    store.commit("setSelectedOrg", selectedOrg);
  }