<template>
  <!-- <teleport to="body"> -->
  <div v-show="show">
    <div class="wl-modal-overlay clear" @click.self="$emit('close')">
      <div
      ref="popup"
      class="filter-popup wl-shadow"
      :style="{ top: popOverTop + 'px', left: popOverLeft + 'px' }"
    >
      <div class="title">
        <div class="text">{{ title }}</div>
        <button @click="$emit('close')" class="close"><font-awesome-icon :icon="['fas', 'x']" style="color: #ffffff;" /></button>
      </div>
      <div>
        <slot name="content"></slot>
      </div>
    </div>
    </div>
  </div>
  <!-- </teleport> -->
</template>
<script lang="ts">
import { onBeforeMount, onUnmounted, ref, watch } from "vue";

export default {
  props: {
    target: { default: () => "" },
    show: { default: () => false },
    title: { default: () => "" },
  },
  setup(props, _ctx) {
    let popOverTop = ref(100);
    let popOverLeft = ref(100);

    const setPosition = () => {
      const targetElement = document.getElementById(props.target) as HTMLElement;
      popOverTop.value = targetElement.offsetTop + 140;
      popOverLeft.value =
        targetElement.offsetLeft + targetElement.clientWidth + 70;
    };

    onBeforeMount(() => {
      window.addEventListener("resize", setPosition);
    });
    onUnmounted(() => {
      window.removeEventListener("resize", setPosition);
    });
    watch(() => props.show, (newVal, _oldVal) => {
      // if 'show' changes to true, call setPosition
      if (newVal) {
        setPosition();
      }
    });

    return { popOverTop, popOverLeft };
  },
};
</script>
