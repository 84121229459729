<template>
  <div :class="`action-bar ${numSelected ? 'actions' : ''}`">
    <div class="action-contents">
      <div class="items-selected">
        <div v-show="numSelected === 0"><slot name="default-title"></slot></div>
        <div v-show="numSelected > 0">
          {{ numSelected }}
          {{ numSelected === 1 ? "Item" : "Items" }} Selected
        </div>
      </div>
      <div class="button-container">
        <div class="button-container-slot" v-show="numSelected === 0">
          <slot></slot>
        </div>
        <div class="button-container-slot" v-show="numSelected > 0">
          <slot name="show-when-items-selected"></slot>
        </div>
      </div>
    </div>
    <div class="action-contents right">
      <div class="button-container">
        <div class="button-container-slot">
          <slot name="utilities"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    numSelected: {
      default: () => 0,
    },
  },
  setup() {},
};
</script>
