<template>
  <div>
    <transition name="fade-slide" mode="out-in">
      <EasyDataTable
        v-if="itemsRef.length > 0 && !isBusy"
        :headers="headersRef"
        :items="itemsRef"
        :sort-type="sortType"
        theme-color="#22abe3"
        v-model:items-selected="itemsSelectedRef"
        :table-class-name="props.tableClassName"
        @update-page-items="onItemsSelectedUpdate"
        :server-items-length="serverItemsLength"
        v-model:server-options="serverOptions"
        must-sort
        no-hover
        :hover-row-to-show-element="props.hoverRowToShowElement"
        :fixed-checkbox="true"
        :checkbox-column-width="props.checkboxColumnWidth"
        :fixed-expand="true"
        :expand-column-width="6"
      >
        <template v-for="(_, slotName) in $slots" v-slot:[slotName]="slotProps">
          <slot :name="slotName" v-bind="slotProps"></slot>
        </template>
      </EasyDataTable>
      <div v-else-if="isBusy">
        <WLSkeleton appearance="table"></WLSkeleton>
      </div>
      <div class="no-data" v-else>
        <img src="/assets/svgs/not_found.svg" />
        <br />
        <br />
        <div class="no-items-message">
          <slot name="no-items-message"
            ><div>It looks like no items loaded.</div></slot
          >
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
//import { test_devices } from '@/data/devices';
import { api } from "../api/api";
import { PropType, computed, onMounted, ref, watch } from "vue";
import type { Item, SortType, ServerOptions } from "wl-vue3-easy-data-table";
import { useStore } from "vuex";
import WLSkeleton from "./WLSkeleton.vue";
import { logException } from "@/utils/app-insights";

export default {
  components: {
    WLSkeleton,
  },
  props: {
    itemsSelected: {
      type: Array as PropType<Item[]>,
      default: () => [],
    },
    checkboxColumnWidth: {
      default: () => 24,
    },
    dataSource: {
      default: () => "",
    },
    dataErrorMessage: {
      default: () => "",
    },
    headers: {
      type: Array as PropType<Item[]>,
      default: () => [],
    },
    shouldLoadTable: {
      default: () => true,
    },
    defaultRowsPerPage: {
      default: () => 25,
    },
    defaultSortBy: {
      default: () => "",
    },
    defaultSortType: {
      type: String as PropType<SortType>,
      default: "",
    },
    hoverRowToShowElement: {
      default: () => false,
    },
    tableClassName: {
      default: () => "wl-table-style",
    },
    clientMode: {
      default: () => true,
    },
  },
  emits: ["update-items-selected", "update-server-options"],
  expose: [
    "clearSelectedItems",
    "loadTable",
    "itemsRef",
    "isBusy",
    "headersRef",
    "shouldLoadTable",
    "serverItemsLength",
    "serverOptions",
  ],
  setup(props, { emit }) {
    const store = useStore();
    const sortType: SortType[] = ["desc", "asc"];
    const headersRef = props.headers;
    let isBusy = ref(true);

    const serverItemsLength = ref();
    const serverOptions = ref<ServerOptions | undefined>({
      page: 1,
      rowsPerPage: props.defaultRowsPerPage,
      sortBy: props.defaultSortBy,
      sortType: props.defaultSortType,
    });

    let itemsRef = ref<Item[]>([]);

    const itemsSelectedRef = ref(props.itemsSelected);

    const clearSelectedItems = async () => {
      itemsSelectedRef.value = [];
    };

    const loadTable = async () => {
      if (!props.shouldLoadTable) return;
      if (props.clientMode) {
        serverOptions.value = undefined;
      }

      itemsSelectedRef.value = [];
      itemsRef.value = [];
      isBusy.value = true;
      try {
        if (!dataSource) {
          console.warn("A table's datasource is missing.");
          return;
        }
        if (!props.headers.length) {
          console.warn(
            'A table is missing the headers property json. See the "const headers" line here, https://hc200ok.github.io/vue3-easy-data-table-doc/features/header-slot.html.'
          );
          return;
        }
        itemsRef.value = (await api.get<Item[]>(
          dataSource.value,
          props.dataErrorMessage
        )) as any;
      } catch (exception: any) {
        logException(
          { exception },
          {
            userId: store.state.user?.id,
            selectedOrgId: store.state.selectedOrg?.id,
          }
        );
      }
      isBusy.value = false;
    };

    onMounted(async () => {
      loadTable();
    });

    const dataSource = computed(() => props.dataSource);
    watch(dataSource, async (_newValue, _oldValue) => {
      await loadTable();
    });

    const onItemsSelectedUpdate = () => {
      emit("update-items-selected", itemsSelectedRef.value);
    };

    watch(
      serverOptions,
      (value) => {
        emit("update-server-options", value);
      },
      { deep: true }
    );

    return {
      isBusy,
      headersRef,
      itemsRef,
      sortType,
      itemsSelectedRef,
      store,
      onItemsSelectedUpdate,
      loadTable,
      clearSelectedItems,
      serverItemsLength,
      serverOptions,
      props,
    };
  },
};
</script>
