import { WLOrg, WLUser } from "@/models/models";
import { createStore } from "vuex";

export default createStore({
  state: {
    user: {} as WLUser,
    selectedOrg: JSON.parse(
      localStorage.getItem("selectedOrg") as any
    ) as WLOrg,
    orgs: JSON.parse(localStorage.getItem("orgs") as any) as WLOrg[],
    toggles: {
      //keeping the following as a reference for toggles
      // showDataCake: JSON.parse(
      //   localStorage.getItem("toggles.showDataCake") as any
      // ) as boolean,
    },
    display: {
      menuIsHidden: false,
      profileIsHidden: false,
    },
    appSettings: getAppSettings(),
    impersonateuid: localStorage.getItem("impersonateuid") as string,
    isAuthenticated: false
  },
  getters: {},
  mutations: {
    setToggle(state: any, toggleObj: { name: string; value: boolean }) {
      state.toggles[toggleObj.name] = toggleObj.value;
      localStorage.setItem(
        `toggles.${toggleObj.name}`,
        JSON.stringify(toggleObj.value)
      );
    },
    setSelectedOrg(state: any, selectedOrg: WLOrg) {
      state.selectedOrg = selectedOrg;
      localStorage.setItem(`selectedOrg`, JSON.stringify(selectedOrg));
    },
    cacheOrgs(state: any, orgs: WLOrg[]) {
      state.orgs = orgs.sort((orgA, orgB) => {
        var shortNameA = orgA.shortName!.toUpperCase();
        var shortNameB = orgB.shortName!.toUpperCase();
        return shortNameA < shortNameB ? -1 : shortNameA > shortNameB ? 1 : 0;
      });
      localStorage.setItem(`orgs`, JSON.stringify(orgs));
    },
    cacheAppSettings(state: any, appSettings: any) {
      state.appSettings = appSettings;
      localStorage.setItem(`appSettings`, JSON.stringify(appSettings));
    },
  },
  modules: {},
});

function getAppSettings() {
  let settings = JSON.parse(localStorage.getItem("appSettings") as any);
  const defaultMeadowCloudAPIHost = window.location.host.includes("localhost") ? "https://localhost:7057/api/v1" : "https://staging.meadowcloud.dev/api/v1";

  if (settings) {
    settings.menuIsCollapsed ||= false;
    settings.meadowCloudApiHost ||= defaultMeadowCloudAPIHost;
  } else {
    settings = {
      menuIsCollapsed: false,
      meadowCloudApiHost: defaultMeadowCloudAPIHost,
    };
  }

  return settings;
}
