import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faX,
  faCopy,
  faCheck,
  faBuildingUser,
  faBook,
  faCreditCard,
  faEnvelope,
  faKey,
  faMicrochip,
  faCubes,
  faLock,
  faUser,
  faUserLock,
  faUsers
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faX,
  faCopy,
  faCheck,
  faBuildingUser,
  faBook,
  faCreditCard,
  faEnvelope,
  faKey,
  faMicrochip,
  faCubes,
  faLock,
  faUser,
  faUserLock,
  faUsers
)