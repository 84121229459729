import { getProfessionalPriceId } from "./pricing";

// Prices/products
export const PRICE_DEVELOPER = "Free";
export const PRICE_PROFESSIONAL = getProfessionalPriceId();

//roles
export const ROLES_CONTRIBUTOR = "Contributor";
export const ROLES_OWNER = "Owner";
export const WLRoles = [ROLES_CONTRIBUTOR, ROLES_OWNER];

// Response Messages
export const TOO_MANY_DEVICES_FOR_FREE_TIER = "TOO_MANY_DEVICES_FOR_FREE_TIER";