<template>
  <!-- <div class="label-filter">
    <input placeholder="Filter by label" autofocus />
  </div> -->
  <div class="wl-labels">
    <div
      v-for="collection in collectionsRef"
      class="label"
      @click="select(collection)"
      tabindex="0"
    >
      <div class="label-row">
        <span
          v-show="hasSelection"
          :class="`${collection.selected ? 'check' : ''}`"
        ></span>
        <!-- <div class="dot" style="background-color: red"></div> -->
        <div class="name">{{ (collection as any).name }}</div>
      </div>
      <!-- <div class="label-description">description</div> -->
      <hr />
    </div>
  </div>
</template>
<script lang="ts">
import { api, apis } from "@/api/api";
import { WLCollection } from "@/models/models";
import { logException } from "@/utils/app-insights";
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";

export default {
  props: {
    show: { default: () => false },
    hasSelection: { default: () => false },
    selectedCollectionId: { default: () => "0"}
  },
  emits: ["select"],
  setup(props, { emit }) {
    const store = useStore();
    let collectionsRef = ref([] as WLCollection[]);

    const orgId = computed(() => store.state?.selectedOrg?.id);

    watch(orgId, async (_newValue, _oldValue) => {
      await loadItems();
    });

    onMounted(async () => {
      await loadItems();
    });

    const loadItems = async () => {
      if (!orgId.value) return;

      try {
        collectionsRef.value = (await api.get<[]>(
          `${apis.meadowCloud}/orgs/${orgId.value}/collections`,
          "Could not load collections!"
        )) as any;
        if (props.hasSelection) {
          const all = {
            id: "0",
            name: "All",
            lastPublishedPackageId: "N/A",
            lastPublishedDate: "0",
            selected: false,
          };
          collectionsRef.value.unshift(all);

          const selectedCollection = collectionsRef.value.find(cc => cc.id === props.selectedCollectionId);
          select(selectedCollection);
        }
      } catch (exception: any) {
        logException({ exception });
      }
    };

    const select = (collection) => {
      deselectCollections();
      collection.selected = true;
      emit("select", collection);
    };

    const deselectCollections = () => {
      for (let collection of collectionsRef.value) {
        collection.selected = false;
      }
    };

    return { collectionsRef, store, select };
  },
};
</script>
