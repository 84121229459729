import { h, markRaw } from "vue";
import MenuSeparator from "../components/MenuSeparator.vue";
import {
  booksSvg,
  devicesSatelliteDishSvg,
  eventHubSvg,
  integrationsSvg,
  packagesBoxSvg,
  webhookSvg,
  datacakeSvg,
  eventGridSvg,
} from "./icons";

const customIcon = (props) => {
  return {
    element: markRaw({
      render: () => h("h1", props),
    }),
  };
};

const renderIntegrations = (shortName) => {
  const integrations = [
    {
      href: `/${shortName}/integrations/webhooks`,
      title: "Webhooks",
      icon: customIcon(webhookSvg),
    },
    {
      href: `/${shortName}/integrations/azureeventhubs`,
      title: "Azure Event Hubs",
      icon: customIcon(eventHubSvg),
    },
    {
      href: `/my/integrations/azureeventgrid`,
      title: "Azure Event Grid",
      icon: customIcon(eventGridSvg),
    },
    {
      href: `/my/integrations/datacake`,
      title: "Datacake",
      icon: customIcon(datacakeSvg),
    }
  ];

  return integrations;
};

export const defaultMenu = [
  { component: markRaw(MenuSeparator) },
  {
    href: "/my/devices",
    title: "Devices",
    icon: customIcon(devicesSatelliteDishSvg),
  },
  {
    href: "/my/packages",
    title: "Packages",
    icon: customIcon(packagesBoxSvg),
  },
  {
    href: "/my/collections",
    title: "Collections",
    icon: customIcon(booksSvg),
  },
  {
    title: "Integrations",
    icon: customIcon(integrationsSvg),
    child: renderIntegrations("my"),
  },
  { component: markRaw(MenuSeparator) },
];

export function buildLoggedInMenu(shortName) {
  if (!shortName) shortName = "my";

  const menu = [
    { component: markRaw(MenuSeparator) },
    {
      href: `/${shortName}/devices`,
      title: "Devices",
      icon: customIcon(devicesSatelliteDishSvg),
    },
    {
      href: `/${shortName}/packages`,
      title: "Packages",
      icon: customIcon(packagesBoxSvg),
    },
    {
      href: `/${shortName}/collections`,
      title: "Collections",
      icon: customIcon(booksSvg),
    },
    {
      title: "Integrations",
      icon: customIcon(integrationsSvg),
      child: renderIntegrations(shortName)
    },
    { component: markRaw(MenuSeparator) },
  ];
  return menu;
}
