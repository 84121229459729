<template>
  <div class="container-flex" tabindex="-1">
    <sidebar-menu
      :menu="store?.state?.isAuthenticated ? menuItems : defaultMenu"
      :show-one-child="true"
      :class="menuIsHidden ? 'mtn-bg menu-hide' : 'mtn-bg'"
      :relative="true"
      :collapsed="store?.state?.appSettings?.menuIsCollapsed"
      @update:collapsed="onToggleCollapse"
    >
      <template v-slot:header>
        <div class="logo">
          <router-link class="vsm--link vsm--link_level-1" to="/" target="_self"
            ><img src="/assets/svgs/icons/logo-header.svg"
          /></router-link>
        </div>
      </template>
      <template v-slot:toggle-icon>
        <img
          v-show="!store?.state?.appSettings?.menuIsCollapsed"
          src="/assets/svgs/nav-collapse.svg"
        />
        <img
          v-show="store?.state?.appSettings?.menuIsCollapsed"
          src="/assets/svgs/nav-open.svg"
        />
      </template>
    </sidebar-menu>
    <div class="page">
      <WLProfileWidget></WLProfileWidget>
      <router-view v-slot="{ Component }">
        <transition name="fade-slide" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  onMounted,
  ref,
  watch,
} from "vue";
import { buildLoggedInMenu, defaultMenu } from "./models/menu";
import { useStore } from "vuex";
import WLProfileWidget from "./components/WLProfileWidget.vue";

export default {
  components: {
    WLProfileWidget,
  },
  setup() {
    //store
    const store = useStore();
    //sidebar
    let menuItems: any = ref(defaultMenu);
    const applyTheme = (name: string) => {
      document.body.className = name;
    };
    let menuIsOpenRef = ref(true);
    
    onMounted(async () => {
      applyTheme("theme-light");
    });

    const orgId = computed(() => store.state?.selectedOrg?.id);

    watch(orgId, (_newValue, _oldValue) => {
      menuItems.value = buildLoggedInMenu(store.state?.selectedOrg?.shortName);
    });

    const menuIsHidden = computed(() => store.state.display.menuIsHidden);

    const onToggleCollapse = (collapsed: boolean) => {
      store.state.appSettings.menuIsCollapsed = collapsed;
      store.commit("cacheAppSettings", store.state.appSettings);
    };

    return {
      menuItems,
      defaultMenu,
      menuIsHidden,
      menuIsOpenRef,
      onToggleCollapse,
      store,
    };
  },
};
</script>

<style lang="scss">
@import "./styles/main.scss";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
}

.content-row {
  height: 100%;
}

.v-sidebar-menu .vsm--link {
  height: 65px;
}
</style>
