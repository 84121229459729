<template>
  <teleport to="body">
    <div>
      <div v-show="isOpen" class="wl-modal-overlay" @mousedown.self="close">
        <div ref="formRef" :class="`wl-modal ${props.classModifiers}`">
          <slot name="content"></slot>
          <div class="buttons">
            <button v-show="hasCancel" @click="close" class="wl-button clear">Cancel</button>
            <slot name="buttons"></slot>
          </div>
          <div class="additional-info">
            <slot name="info"></slot>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>
<script lang="ts">
import { nextTick, onMounted, onUnmounted, ref } from "vue";

export default {
  expose: ["data", "open", "close"],
  emits: ["change", "keysAltEnter"],
  props: {
    classModifiers: { default: () => "" },
    hasCancel: {default: () => true}
  },
  setup(props, { emit }) {
    const isOpen = ref(false);
    const formRef = ref();
    let data: Object = {};

    const open = async () => {
      isOpen.value = true;
      const container = document.getElementsByClassName(
        "container-flex"
      )[0] as HTMLElement;
      container?.classList.add("blur");

      await setFocusOnFirstInput();

      //TODO: lock the focus to the modal
    };

    const close = () => {
      isOpen.value = false;
      const container = document.getElementsByClassName(
        "container-flex"
      )[0] as HTMLElement;
      container?.classList.remove("blur");
    };

    const escapeListener = (event) => {
      if (event.key === "Escape" || event.keyCode === 27) {
        close();
      }
    };

    const keyListener = (event) => {
      if (event.altKey && event.key === "Enter") {
        emit("keysAltEnter", "");
      }
      // NOTE: could else if for other key combos
    };

    onMounted(() => {
      if (formRef.value) {
        const inputs = formRef.value.querySelectorAll(
          "input"
        ) as HTMLInputElement[];
        for (let input of inputs) {
          input.addEventListener("input", onChange);
        }
      }

      addEventListener("keyup", escapeListener);
      addEventListener("keypress", keyListener);
    });

    onUnmounted(() => {
      if (formRef.value) {
        const inputs = formRef.value.querySelectorAll(
          "input"
        ) as HTMLInputElement[];
        for (let input of inputs) {
          input.removeEventListener("input", onChange);
        }
      }
      removeEventListener("keyup", escapeListener);
      removeEventListener("keydown", keyListener);
    });

    const onChange = () => {
      emit("change", "");
    };

    return { props, data, isOpen, open, close, formRef };

    async function setFocusOnFirstInput() {
      const inputElements = (formRef.value as HTMLElement).getElementsByTagName(
        "input"
      );
      const selectElements = (
        formRef.value as HTMLElement
      ).getElementsByTagName("select");
      const firstInput = inputElements[0] || selectElements[0] || null;
      if (firstInput) {
        await nextTick();
        firstInput.focus();
      }
    }
  },
};
</script>
