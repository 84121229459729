<template>
  <div v-if="!isDismissed" :class="`alert alert-${alertType}`">
    <div class="alert-title">
      <slot name="title"></slot>
    </div>
    <div class="alert-content">
      <slot></slot>
    </div>
    <div class="alert-actions">
      <slot name="buttons"></slot>
    </div>
    <button v-if="showClose" class="alert-close" @click="dismissAlert">&#x2715;</button>
  </div>
</template>

<script lang="ts">
import { onMounted, ref, watch } from 'vue';

export default {
  props: {
    id: {
      type: String,
      default: ""
    },
    alertType: {
      type: String,
      default: 'note',
      validator: (value: string): boolean => ['note', 'tip', 'information', 'warning', 'danger'].includes(value.toLowerCase()),
    },
    showClose: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isDismissed = ref(false);

    const dismissAlert = () => {
      localStorage.setItem(`alert-dismissed-${props.id}`, 'true');
      isDismissed.value = true;
    };

    onMounted(() => {
      const dismissed = localStorage.getItem(`alert-dismissed-${props.id}`);
      if (dismissed) {
        isDismissed.value = true;
      }
    });

    watch(() => props.alertType, () => {
      // Reset dismiss state when alert type changes
      const dismissed = localStorage.getItem(`alert-dismissed-${props.id}`);
      isDismissed.value = !!dismissed;
    });

    return { isDismissed, dismissAlert };
  },
};
</script>
