<template>
  <div>
    <div v-if="!appearanceRef || appearanceRef === 'article'">
      <b-skeleton animation="throb" width="85%"></b-skeleton>
      <b-skeleton animation="throb" width="55%"></b-skeleton>
      <b-skeleton animation="throb" width="70%"></b-skeleton>
      <b-skeleton animation="throb" width="85%"></b-skeleton>
      <b-skeleton animation="throb" width="55%"></b-skeleton>
      <b-skeleton animation="throb" width="70%"></b-skeleton>
      <b-skeleton animation="throb" width="85%"></b-skeleton>
      <b-skeleton animation="throb" width="55%"></b-skeleton>
      <b-skeleton animation="throb" width="70%"></b-skeleton>
    </div>
    <b-table-simple
      v-else-if="appearanceRef === 'table'"
      :table-props="{ bordered: false, striped: true }"
    >
      <thead>
        <tr>
          <th>
            <div
              class="b-skeleton b-skeleton-text b-skeleton-animate-throb"
            ></div>
          </th>
          <th>
            <div
              class="b-skeleton b-skeleton-text b-skeleton-animate-throb"
            ></div>
          </th>
          <th>
            <div
              class="b-skeleton b-skeleton-text b-skeleton-animate-throb"
            ></div>
          </th>
          <th>
            <div
              class="b-skeleton b-skeleton-text b-skeleton-animate-throb"
            ></div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <div
              class="b-skeleton b-skeleton-text b-skeleton-animate-throb"
              style="width: 75%"
            ></div>
          </td>
          <td>
            <div
              class="b-skeleton b-skeleton-text b-skeleton-animate-throb"
              style="width: 75%"
            ></div>
          </td>
          <td>
            <div
              class="b-skeleton b-skeleton-text b-skeleton-animate-throb"
              style="width: 75%"
            ></div>
          </td>
          <td>
            <div
              class="b-skeleton b-skeleton-text b-skeleton-animate-throb"
              style="width: 75%"
            ></div>
          </td>
        </tr>
        <tr>
          <td>
            <div
              class="b-skeleton b-skeleton-text b-skeleton-animate-throb"
              style="width: 75%"
            ></div>
          </td>
          <td>
            <div
              class="b-skeleton b-skeleton-text b-skeleton-animate-throb"
              style="width: 75%"
            ></div>
          </td>
          <td>
            <div
              class="b-skeleton b-skeleton-text b-skeleton-animate-throb"
              style="width: 75%"
            ></div>
          </td>
          <td>
            <div
              class="b-skeleton b-skeleton-text b-skeleton-animate-throb"
              style="width: 75%"
            ></div>
          </td>
        </tr>
        <tr>
          <td>
            <div
              class="b-skeleton b-skeleton-text b-skeleton-animate-throb"
              style="width: 75%"
            ></div>
          </td>
          <td>
            <div
              class="b-skeleton b-skeleton-text b-skeleton-animate-throb"
              style="width: 75%"
            ></div>
          </td>
          <td>
            <div
              class="b-skeleton b-skeleton-text b-skeleton-animate-throb"
              style="width: 75%"
            ></div>
          </td>
          <td>
            <div
              class="b-skeleton b-skeleton-text b-skeleton-animate-throb"
              style="width: 75%"
            ></div>
          </td>
        </tr>
        <tr>
          <td>
            <div
              class="b-skeleton b-skeleton-text b-skeleton-animate-throb"
              style="width: 75%"
            ></div>
          </td>
          <td>
            <div
              class="b-skeleton b-skeleton-text b-skeleton-animate-throb"
              style="width: 75%"
            ></div>
          </td>
          <td>
            <div
              class="b-skeleton b-skeleton-text b-skeleton-animate-throb"
              style="width: 75%"
            ></div>
          </td>
          <td>
            <div
              class="b-skeleton b-skeleton-text b-skeleton-animate-throb"
              style="width: 75%"
            ></div>
          </td>
        </tr>
        <tr>
          <td>
            <div
              class="b-skeleton b-skeleton-text b-skeleton-animate-throb"
              style="width: 75%"
            ></div>
          </td>
          <td>
            <div
              class="b-skeleton b-skeleton-text b-skeleton-animate-throb"
              style="width: 75%"
            ></div>
          </td>
          <td>
            <div
              class="b-skeleton b-skeleton-text b-skeleton-animate-throb"
              style="width: 75%"
            ></div>
          </td>
          <td>
            <div
              class="b-skeleton b-skeleton-text b-skeleton-animate-throb"
              style="width: 75%"
            ></div>
          </td>
        </tr>
      </tbody>
    </b-table-simple>
  </div>
</template>
<script lang="ts">
import { ref } from 'vue';

export default {
  props: {
    appearance: {
      default: () => "article",
    },
  },
  setup(props)
  {
    const appearanceRef = ref(props.appearance);

    return {appearanceRef};
  }
};
</script>
