<template>
  <div class="content">
    <WLBreadCrumbHeader
      title="Devices"
      info-link="https://developer.wildernesslabs.co/Meadow/Meadow.Cloud/Device_Provisioning/"
    />

    <div class="content-container">
      <WLActionBar :num-selected="itemsSelectedRef.length">
        <template v-slot:show-when-items-selected>
          <a
            @click="openCommandAndControlModal"
            class="wl-link-button"
            tabindex="1"
            >Send Command</a
          >
          
          <router-link
                class="wl-link-button"
                :to="`/${store.state?.selectedOrg?.shortName}/analytics?deviceIds=${itemsSelectedRef.map((device) => device.id).join(',')}`"
                >Analytics</router-link
              >

          <a @click="openDeleteModal" class="wl-link-button danger" tabindex="1"
            >Delete</a
          >
          <span
            class="wl-link-button"
            @click="isMoveToCollectionPopupVisible = true"
            id="moveToCollectionButton"
          >
            Move to Collection <span class="wl-caret-down"></span>
          </span>
        </template>
        <template v-slot:utilities>
          <span
            class="wl-link-button"
            @click.prevent="isSelectCollectionPopupVisible = true"
            id="selectCollectionButton"
            >Showing {{ currentCollectionRef.name }}
            <span class="wl-caret-down"></span>
          </span>
        </template>
      </WLActionBar>
      <WLTable
        ref="tableRef"
        @update-items-selected="onItemsSelectedUpdate"
        v-if="store.state?.selectedOrg?.id"
        :data-source="`${apis.meadowCloud}/orgs/${store.state?.selectedOrg?.id}/devices?collectionId=${currentCollectionRef.id}`"
        data-error-message="Failed to load devices!"
        :headers="headers"
        hover-row-to-show-element
      >
        <template #item-id="{ id }">
          <div class="monospace">
            {{ id }}
          </div>
        </template>

        <template #item-name="{ name }">
          <div v-if="name">{{ name }}</div>
          <div class="tiny" v-else>(Click "Edit" to enter name)</div>
        </template>

        <template #hover="item">
          <WLHoverMenu>
            <template #right>
              <router-link
                class="wl-link-button"
                :to="`/${store.state?.selectedOrg?.shortName}/search?q=source:log deviceId:${item.id}`"
                >Logs</router-link
              >
              <router-link
                class="wl-link-button"
                :to="`/${store.state?.selectedOrg?.shortName}/analytics?deviceIds=${item.id}`"
                >Analytics</router-link
              >
              <a
                @click.prevent="openEditModal($event, item.id, item.name)"
                class="wl-link-button"
                >Edit</a
              >
            </template>
          </WLHoverMenu>
        </template>

        <template #item-healthCheckInDate="{ healthCheckInDate }">
          <div v-if="!healthCheckInDate">Never</div>
          <div v-else>
            {{ dateFromNow(healthCheckInDate) }}
          </div>
        </template>

        <template #no-items-message>
          <div>
            Whoops, looks like you don't have any devices, yet. Try filtering by
            a different collection or
            <a
              href="https://developer.wildernesslabs.co/Meadow/Meadow.Cloud/Device_Provisioning/"
              target="_blank"
              >check out the docs</a
            >
            to get started. To see how to add
          </div>
        </template>
      </WLTable>
    </div>
    <WLPopOver
      :show="isSelectCollectionPopupVisible"
      @close="isSelectCollectionPopupVisible = false"
      title="Select collection..."
      target="selectCollectionButton"
    >
      <template v-slot:content>
        <WLCollectionSelector
          :hasSelection="true"
          :selectedCollectionId="currentCollectionRef.id"
          @select="selectCollection"
        ></WLCollectionSelector>
      </template>
    </WLPopOver>
    <WLPopOver
      :show="isMoveToCollectionPopupVisible"
      @close="isMoveToCollectionPopupVisible = false"
      title="Move to collection..."
      target="moveToCollectionButton"
    >
      <template v-slot:content>
        <WLCollectionSelector @select="moveToCollection"></WLCollectionSelector>
      </template>
    </WLPopOver>
    <WLModal ref="modalRef">
      <template v-slot:content>
        <img src="/assets/svgs/icons/exclamation-triangle.svg" />
        <h2>Warning!</h2>
        <p>Are you sure that you want to permanently remove these devices?</p>
      </template>
      <template v-slot:buttons>
        <button @click="doDelete" class="wl-button danger">Delete</button>
      </template>
    </WLModal>
    <WLCommandAndControlModal
      ref="commandAndControlModal"
      @success="commandSuccess"
    ></WLCommandAndControlModal>
    <WLEditDeviceModal
      ref="editDeviceModalRef"
      @success="editDeviceSuccess"
    ></WLEditDeviceModal>
  </div>
</template>

<script lang="ts">
import WLTable from "@/components/WLTable.vue";
import WLActionBar from "@/components/WLActionBar.vue";
import { nextTick, onBeforeMount, ref } from "vue";
import { Header, Item } from "wl-vue3-easy-data-table";
import { useStore } from "vuex";
import WLModal from "../components/WLModal.vue";
import WLBreadCrumbHeader from "../components/WLBreadCrumbHeader.vue";
import { api, apis } from "@/api/api";
import { logException } from "@/utils/app-insights";
import WLCollectionSelector from "@/components/WLCollectionSelector.vue";
import WLPopOver from "@/components/WLPopOver.vue";
import { getCleanDateWithTime, dateFromNow } from "@/utils/misc";
import { WLCollection } from "@/models/models";
import WLCommandAndControlModal from "@/components/modals/WLCommandAndControlModal.vue";
import WLEditDeviceModal from "@/components/modals/devices/WLEditDeviceModal.vue";
import router from "@/router";

export default {
  components: {
    WLActionBar,
    WLTable,
    WLModal,
    WLPopOver,
    WLCollectionSelector,
    WLBreadCrumbHeader,
    WLCommandAndControlModal,
    WLEditDeviceModal,
  },
  setup() {
    const store = useStore();
    const modalRef = ref(WLModal);
    const tableRef = ref(WLTable);
    const commandAndControlModal = ref<typeof WLCommandAndControlModal>(
      WLCommandAndControlModal
    );
    const editDeviceModalRef = ref<typeof WLEditDeviceModal>(WLEditDeviceModal);
    let isMoveToCollectionPopupVisible = ref(false);
    let isSelectCollectionPopupVisible = ref(false);
    const headers: Header[] = [
      { text: "ID", value: "id", sortable: true, width: 100 },
      { text: "Name", value: "name", sortable: true, width: 100 },
      { text: "Version", value: "version", sortable: true, width: 40 },
      {
        text: "Last Heartbeat",
        value: "healthCheckInDate",
        sortable: true,
        width: 60,
      },
    ];

    //UI odds and ends
    let currentCollectionRef = ref<WLCollection>({ id: "0", name: "" });

    const itemsSelectedRef = ref<Item[]>([]);

    onBeforeMount(() => {
      currentCollectionRef.value.id = router.currentRoute.value.query
        .collectionId as string | "";
    });

    const onItemsSelectedUpdate = (items: Item[]) => {
      itemsSelectedRef.value = items;

      if (itemsSelectedRef.value.length === 0) {
        isMoveToCollectionPopupVisible.value = false;
      } else {
        isSelectCollectionPopupVisible.value = false;
      }
    };

    const openDeleteModal = () => {
      (modalRef.value as any).open();
    };

    const openCommandAndControlModal = () => {
      commandAndControlModal.value.open(
        "devices",
        itemsSelectedRef.value.map((item) => item.id)
      );
    };

    const doDelete = async () => {
      try {
        await api.delete<any, Item[]>(
          `${apis.meadowCloud}/devices/`,
          {
            ids: itemsSelectedRef.value.map((device) => device.id),
          },
          "Failed to delete one or more devices!"
        );
      } catch (exception: any) {
        logException({ exception });
      }
      (modalRef.value as any).close();
      await (tableRef.value as any).loadTable();
      await nextTick();
      itemsSelectedRef.value = [];
    };

    const moveToCollection = async (collection: WLCollection) => {
      if (!collection?.id) return;

      const deviceIds = itemsSelectedRef.value.map((item) => item.id);
      try {
        await api.post<any, string>(
          `${apis.meadowCloud}/collections/${collection.id}/devices`,
          { deviceIds },
          "Failed to move devices to collection!"
        );
      } catch {}

      isMoveToCollectionPopupVisible.value = false;
      await (tableRef.value as any).loadTable();
      await nextTick();
      itemsSelectedRef.value = [];
    };

    const selectCollection = async (collection: WLCollection) => {
      if (!collection?.id) return;

      currentCollectionRef.value.id = collection.id!;
      currentCollectionRef.value.name = collection.name!;
      isSelectCollectionPopupVisible.value = false;
      await nextTick();
      await (tableRef.value as any).loadTable();
      await nextTick();
      itemsSelectedRef.value = [];

      //query string
      const currentQuery = { ...router.currentRoute.value.query };
      if (currentCollectionRef.value.id === "0") {
        delete currentQuery.collectionId; // Remove 'collectionId' if it is "0"
      } else {
        currentQuery.collectionId = currentCollectionRef.value.id;
      }
      router.push({ query: currentQuery });
    };

    const openEditModal = async (_event: Event, id: string, name: string) => {
      editDeviceModalRef.value.open(id, name);
    };

    const commandSuccess = () => {
      tableRef.value.clearSelectedItems();
    };

    const editDeviceSuccess = () => {
      tableRef.value.loadTable();
    };

    return {
      apis,
      store,
      headers,
      itemsSelectedRef,
      currentCollectionRef,
      onItemsSelectedUpdate,
      openDeleteModal,
      doDelete,
      commandSuccess,
      editDeviceSuccess,
      openCommandAndControlModal,
      openEditModal,
      modalRef,
      tableRef,
      commandAndControlModal,
      editDeviceModalRef,
      isSelectCollectionPopupVisible,
      isMoveToCollectionPopupVisible,
      getCleanDateWithTime,
      moveToCollection,
      selectCollection,
      dateFromNow,
    };
  },
};
</script>
