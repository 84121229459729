
import { ApplicationInsights, ITraceTelemetry, IExceptionTelemetry, ICustomProperties } from '@microsoft/applicationinsights-web'

const appInsights = new ApplicationInsights({ config: {
    connectionString: getAppInsightsConnectionString()
  } });
  appInsights.loadAppInsights();


function getAppInsightsConnectionString() {
if (
    !window.location.host ||
    window.location.host.includes("localhost") ||
    window.location.host.includes("yellow-cliff") ||
    window.location.host.includes("staging")
) {
    return "InstrumentationKey=1d98b384-3380-4cfc-a018-88ea5f866269;IngestionEndpoint=https://centralus-2.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com/";
}

//production
return "InstrumentationKey=e4b9d6d2-b039-4ca6-a724-63a11714955f;IngestionEndpoint=https://centralus-2.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com/";
}

export function logPageView()
{
    appInsights.trackPageView();
    appInsights.flush();
}

export function logException(exception:IExceptionTelemetry, customProperties?:ICustomProperties)
{
    appInsights.trackException(exception, customProperties);
    appInsights.flush();
}

export function logTrace(telem:ITraceTelemetry, customProperties?:ICustomProperties)
{
    appInsights.trackTrace(telem, customProperties);
}