<template>
  <WLModal ref="modalRef">
    <template v-slot:content>
      <p>Switch to another organization?</p>
      <b-form-select v-model="selectedOrgRef" :options="orgsRef" @change="selectOrg" autofocus></b-form-select>
      <br />
      <a @click="viewOrgs()" class="wl-link-button">View your organizations</a>
    </template>
  </WLModal>
</template>

<script lang="ts">
import WLModal from "../WLModal.vue";
import router from "@/router";
import { updateRouterFromNewOrg } from "@/utils/misc";
import { ref } from "vue";
import { useStore } from "vuex";

export default {
  components: {
    WLModal,
  },
  expose: ["open", "close"],
  emits: ["success"],
  setup(_props) {
    const modalRef = ref<typeof WLModal>(WLModal);
    const store = useStore();
    const orgsRef = ref(store.state?.orgs?.map(org => {
      return {
        value: org.id,
        text: org.name
      }
    }));
    const selectedOrgRef = ref(orgsRef.value?.find(org => org.value === store.state.selectedOrg.id).value);

    const open = () => {
      modalRef.value.open();
    };

    const close = () => {
      modalRef.value.close();
    };

    const selectOrg = (value) => {
      const id = value;
      const org = store.state?.orgs?.find((org) => org.id === id);
      updateRouterFromNewOrg(org, router);
      store.commit("setSelectedOrg", org);
      modalRef.value.close();
    };

    const viewOrgs = () => {
      router.push("/orgs");
      modalRef.value.close();
    };

    return {
      open,
      close,
      modalRef,
      orgsRef,
      selectedOrgRef,
      selectOrg,
      viewOrgs,
      store,
    };
  },
};
</script>
