<template>
  <span class="copy-to-clipboard">

      <a
        v-show="!isBeingCopiedRef"
        id="popover-target-1"
        class="wl-link-button"
        :title="tooltip"
        @click="copyText()"
      >
        <font-awesome-icon :icon="['fa', 'copy']" class="icon" />
      </a>

      <font-awesome-icon
        v-show="isBeingCopiedRef"
        :icon="['fa', 'check']"
        class="icon check"
      />
  </span>
</template>
<script lang="ts">
import { ref } from "vue";

export default {
  props: {
    textToCopy: {
      default: () => "",
    },

    tooltip: {
      default: () => "",
    },
  },
  setup(props) {
    const isBeingCopiedRef = ref(false);

    const copyText = () => {
      isBeingCopiedRef.value = true;
      navigator.clipboard.writeText(props.textToCopy);

      setTimeout(() => (isBeingCopiedRef.value = false), 2000);
    };

    return { isBeingCopiedRef, copyText };
  },
};
</script>
