<template>
  <WLModal ref="modalRef" @keysAltEnter="save">
      <template v-slot:content>
        <h2>Edit Device</h2>
        <p>Please enter the name of the device.</p>
        <input ref="inputDeviceNameRef" type="text" placeholder="Device name..." />
      </template>
      <template v-slot:buttons>
        <button @click="save" class="wl-button" >Save</button>
      </template>
    </WLModal>
</template>

<script lang="ts">
import WLModal from "@/components/WLModal.vue";
import { api, apis } from "@/api/api";
import { logException, logTrace } from "@/utils/app-insights";
import { nextTick, ref } from "vue";
import { useStore } from "vuex";
import { toast } from "vue3-toastify";

export default {
  components: {
    WLModal,
  },
  expose: ["open", "close"],
  emits: ["success", "fail"],
  setup(_props, { emit }) {
    const modalRef = ref<typeof WLModal>(WLModal);
    const store = useStore();
    let deviceId:string;
    const inputDeviceNameRef = ref<any>(HTMLInputElement);
    
    const open = async (id: string = "", name: string = "") => {
      deviceId = id;
      inputDeviceNameRef.value.value = name;
      modalRef.value.open();
      await nextTick();
      inputDeviceNameRef.value.focus()
    };

    const close = () => {
      modalRef.value.close();
    };

    const save = async (event:Event) => {
      if (!deviceId) {
        logTrace(
          {
            message: "Error: No id supplied when trying to edit a device.",
          },
          {
            userId: store.state.user.userId,
            orgId: store.state.selectedOrg.id,
          }
        );
        return;
      }

      const button = event.target as HTMLAnchorElement;
      button?.classList.add("loading");

      let name = inputDeviceNameRef.value.value;

      let response;
      try {
        response = await api.post<any, any>(
          `${apis.meadowCloud}/devices/${deviceId}`,
          {
            name,
          },
          "Failed to update the device!"
        );
      } catch (exception: any) {
        logException({ exception });
      }

      button?.classList.remove("loading");
      if (typeof response === "string") {
        emit("fail", response);
        toast(response, { position: "top-center", type: "error" });
      } else {
        emit("success");
        inputDeviceNameRef.value.value = "";
        modalRef.value.close();
        toast("Device updated successfully!");
      }
    };

    return {
      open,
      close,
      save,
      modalRef,
      inputDeviceNameRef
    };
  },
};
</script>
