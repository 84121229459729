<template>
  <div class="hover-menu">
    <div>
      <slot name="left"> </slot>
    </div>
    <div>
      <slot name="center"> </slot>
    </div>
    <div>
      <slot name="right"> </slot>
    </div>
  </div>
</template>
<script lang="ts">
export default {
  setup() {
    return {};
  },
};
</script>
